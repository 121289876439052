$bg-color-level-1: rgba(197,225,165, 0.11);
$bg-color-level-2: rgba(197,225,165, 0.16);
$text-color: #FFF;
$navigate-color: #FFF;
$navigate-color-hover: #C5E1A5;
$navigate-color-null: rgba(197,225,165, 0.40);
$header-color: #C5E1A5;
$link-color: #90CAF9;
$link-color-hover: #42A5F5;
$code-color: #EF9A9A;
$default-font-size: 1.8rem;

/* Fonts */
@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Muli/Muli-Regular.ttf) format("truetype");
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

/* HTML Header tags */
h1 {
  font-size: 4.0rem;
  padding: 20px 0 12px 0;
}
h2 {
  font-size: 2.8rem;
  padding: 20px 0 8px 0;
}
h3 {
  font-size: 2.5rem;
  padding: 15px 0 6px 0;
}
h4 {
  font-size: 2.0rem;
  padding: 10px 0 4px 0;
}
h5 {
  font-size: 1.7rem;
  padding: 5px 0 0 0;
}
h6 {
  font-size: 1.5rem;
  padding: 0px 0 0 0;
}

/* Main HTML */
html {
  position: relative;
  min-height: 100%;
  font-size: 10px;
  @media (max-width: 767px) {
    font-size: 8px;
  }
}
body {
  background-color: #121212;
  margin: 0 0 0 0;
  font-family: Muli;
}

/* Site header */
.header {
  background-color: $bg-color-level-2;
  color: $text-color;
  padding: 1px 50px 1px 50px;
  text-align: center;
  font-family: Montserrat;
  h1 {
    margin: 10px;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: $navigate-color;
  }
  a:hover {
    color: $navigate-color-hover;
  }
}
.nav {
  background-color: $bg-color-level-2;
  color: $text-color;
  padding: 15px;
  text-align: center;
  font-family: Montserrat;
  font-size: 19px;
  a {
    display: inline-block;
    padding: 15px 30px;
    color: $navigate-color;
    text-decoration: none;
    margin-left: 0px;
    margin-bottom: 5px;
    border-radius: 5px;
  }
  a:hover {
    background-color: $bg-color-level-1;
    color: $navigate-color-hover;
  }
  .active {
    background-color: $bg-color-level-1;
  }
}

/* Site footer */
.footer {
  position: absolute;
  bottom: 0px;
  height: 80px;
  width: 100%;
}

/* Site content */
.content {
  max-width: 720px;
  margin: auto;
  padding: 10px 20px 10px 20px;
  color: $text-color;
  word-wrap: break-word;
  text-align: left;
  font-size: $default-font-size;
  line-height: 24pt;
  @media (max-width: 767px) {
    line-height: 15pt;
  }
  a {
    color: $link-color;
  }
  a:hover {
    color: $link-color-hover;
  }
  h1 {
    font-size: 2.8rem;
    padding: 20px 0 8px 0;
    border-bottom: 3px solid $header-color;
    text-align: left;
  }
  h2 {
    font-size: 2.5rem;
    padding: 15px 0 6px 0;
    border-width: 1rem;
    border-bottom: 2px solid $header-color;
  }
  h3 {
    font-size: 2.0rem;
    padding: 10px 0 4px 0;
    border-bottom: 1px solid $header-color;
  }
  h4 {
    font-size: $default-font-size;
    padding: 5px 0 0 0;
    border-bottom: 1px dashed $header-color;
  }
  h5 {
    font-size: 1.5rem;
    padding: 0px 0 0 0;
    border-bottom: 1px dashed $header-color;
  }
  h6 {
    font-size: 1.2rem;
    padding: 0px 0 0 0;
    border-bottom: 1px dashed $header-color;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  table, th, td {
    border: 1px solid #FFF;
    background-color: $bg-color-level-2;
    color: $text-color;
  }
}

/* Blog post */
.post-footer {
  margin: 15px 0 20px;
  font-size: $default-font-size;
}
.post-header {
  margin-top: 30px;
  padding: 20px 30px 20px;
  background-color: $bg-color-level-2;
  border-radius: 5px 5px 0 0;
  font-family: Montserrat;
  text-align: left;
  a {
    text-decoration: none;
    color: $navigate-color;
  }
  a:hover {
    color: $navigate-color-hover;
  }
  .post-title {
    font-size: 3.2rem;
    font-weight: bold;
    line-height: 35pt;
  }
  .post-metadata {
    margin-top: 20px;
  }
}
.post-body {
  text-align: left;
}
.post-image {
  background-color: rgba(200, 230, 201, 0.11);
  img {
    max-height: 600px;
  } 
}
.content {
  .post-read-more {
    display: inline-block;
    margin: 5px 0 5px;
    padding: 10px 10px;
    border-radius: 5px;
    background-color: $bg-color-level-2;
    text-decoration: none;
    color: $navigate-color;
    font-size: $default-font-size;
    line-height: 1.4;
  }
  .post-read-more:hover {
    color: $navigate-color-hover;
  }
}

/* Images */
img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
}
.img-pad {
  padding: 5px;
}
.img-center {
  text-align: center;
}
.img-caption {
  text-align: center;
  display: inline-block;
  align-items: center;
  margin: auto;
  /* overflow: hidden; /* Allows border-radius to work */
  border-radius: 0 0 5px 5px;
  font-size: $default-font-size;
}
.img-caption-text {
  background-color: $bg-color-level-2;
  padding: 5px;
}
.gallery-box {
  display: inline-block;
  margin: 20px 0 20px 0;
}
.gallery-2 {
  display: inline;
  float: left;
  width: 50%;
}
.gallery-3 {
 display: inline;
 float: left;
 width: 33%;
}
.caption {
  text-align: center;
  font-style: italic;
}

/* Blog navigation */
.page-controls {
  text-align: center;
  margin: 20px 0 80px;
}
.page-previous-null,.page-next-null {
  color: $navigate-color-null;
}

/* Code display */
.prettyprint {
  padding: 10px;
  color: $text-color; /* Backup in case no js */
  white-space: pre-wrap;
}
code {
  color: $code-color;
  white-space: pre-wrap;
}
